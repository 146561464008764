

<template>
  <div>
    <div class="box">
      <div class="item" v-for="item in url_list">
        <div><a :href="item.url" target="_blank" :title="item.url">{{item.name}}</a></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "one",
  data(){
    return{
      url_list:[

        {
          name:"智慧厕所后台",
          url:'https://toilet-system.bcitymap.com/'
        },

        {
          name:"诗画浙江",
          url:'https://dt.tourzj.gov.cn/portal/sys/index.do'
        },
        {
          name:"总平台后台",
          url:'https://nanhu-wxapp.bcitymap.com/mall-admin/index.html#/store-list'
        },
        {
          name:"南湖文旅E管家",
          url:'https://wyegj.nhszwl.com/sso/#/'
        },
        {
          name:"智慧园区管理平台",
          url:'https://183.134.52.251:1443/portal/ui/login?service=https://183.134.52.251:1443/portal/'
        },
        {
          name:"智慧景区管理平台",
          url:'https://36.22.179.126:5443/portal/ui/login?service=https://36.22.179.126:5443/portal/'
        },
        {
          name:"AI中台",
          url:'http://183.134.52.254:11182/algorithmRepo'
        },
      ]
    }
  }
}
</script>
<style scoped >
.box{
  width: 820px;
  height: 500px;
  //background-color: ;
  background: linear-gradient(cornflowerblue, pink);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 15px;
  transform: translate(-50%,-50%);
  box-shadow: 1px 1px 20px #B8ACDB;
}
.item{
  width: 400px;
  height: 40px;
  border-radius: 15px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 200ms;
}
a{
  text-decoration: none; /* 去除默认的下划线 */
  outline: none; /* 去除旧版浏览器的点击后的外虚线框 */
  color: #000; /* 去除默认的颜色和点击后变化的颜色 */
}
.item:hover{
  cursor: pointer;
  width: 420px;
  height: 50px;
  font-size: 22px;
}
</style>
